import type { ComponentType } from "react"
import React from "react"

type ShortcutsProps = {
    id: string
    layoutId?: string
    height: string
    width: string
    name?: string
    ref?: React.RefObject<HTMLElement>
}

type PressedKeysEventDetail = string[]
type PressedKeysEvent = CustomEvent<PressedKeysEventDetail>

export function withKeyboardShortcuts(
    Component: ComponentType<ShortcutsProps>
): ComponentType<ShortcutsProps> {
    const handleKeyDown = (event: KeyboardEvent) => {
        // Don't capture input if the user is typing in an input field/textarea or a meta key is pressed
        if (
            event.target instanceof HTMLInputElement ||
            event.target instanceof HTMLTextAreaElement
        ) {
            return
        }
        if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
            return
        }

        // Save pressed keys to session storage
        let pressedKeys = JSON.parse(
            sessionStorage.getItem("pressedKeys") || "[]"
        )
        pressedKeys.push(event.key)
        if (pressedKeys.length > 1) pressedKeys.shift()
        sessionStorage.setItem("pressedKeys", JSON.stringify(pressedKeys))

        if (typeof window !== "undefined") {
            // Notify other components
            const customEvent = new CustomEvent("pressedKeysChanged", {
                detail: pressedKeys,
            })

            window.dispatchEvent(customEvent)
        }
    }

    const handleBeforeUnload = () => {
        sessionStorage.removeItem("pressedKeys")
    }

    return (props: ShortcutsProps) => {
        if (typeof window !== "undefined") {
            React.useEffect(() => {
                // Focus window on load
                window.focus()

                window.addEventListener("keydown", handleKeyDown)
                window.addEventListener("beforeunload", handleBeforeUnload)

                return () => {
                    window.removeEventListener("keydown", handleKeyDown)
                    window.removeEventListener(
                        "beforeunload",
                        handleBeforeUnload
                    )
                }
            }, [])
        }

        return <Component {...props} />
    }
}

export function withShortcutTrigger(
    Component: ComponentType<ShortcutsProps>
): ComponentType<ShortcutsProps> {
    const handlePressedKeysChanged = (
        event: PressedKeysEvent,
        key: string,
        containerRef: React.RefObject<HTMLElement>
    ) => {
        const pressedKeys = event.detail

        // Check if the last pressed key matches the shortcut
        if (
            pressedKeys[pressedKeys.length - 1].toLowerCase() ===
            key.toLowerCase()
        ) {
            event.preventDefault()
            setTimeout(() => {
                if (containerRef.current) {
                    const button = containerRef.current.querySelector("button")
                    const link = containerRef.current.querySelector("a")
                    if (button) {
                        button.click()
                    } else if (link) {
                        link.click()
                    } else {
                        console.error(
                            `🦎 Error: The shortcut trigger element ${key} isn't working properly. Check the docs for help.`
                        )
                    }
                }

                /* Commented out for now
                else {
                    
                    console.error(
                        `🦎 Error: The shortcut trigger element ${key} isn't working properly. Check the docs for help.`
                    )
                }
                */

                sessionStorage.removeItem("pressedKeys")
            }, 0)
        }
    }

    return (props: ShortcutsProps) => {
        const regExp = /\[(.*?)\]/
        let lizardKeyRegex = props.name?.match(regExp)
        let lizardKey = ""
        let lizardContainerRef = React.useRef(null)

        if (lizardKeyRegex) {
            lizardKey = lizardKeyRegex[1]
        } else {
            console.error(
                `🦎 Error: Your shortcut trigger layer (${props.name}) is missing a key in its layer name.`
            )
        }

        if (typeof window !== "undefined") {
            React.useEffect(() => {
                const handlePressedKeysEvent: EventListener = (
                    event: Event
                ) => {
                    const customEvent = event as PressedKeysEvent
                    handlePressedKeysChanged(
                        customEvent,
                        lizardKey,
                        lizardContainerRef
                    )
                }

                window.addEventListener(
                    "pressedKeysChanged",
                    handlePressedKeysEvent
                )
            }, [])
        }

        return (
            <span
                ref={lizardContainerRef}
                data-lizard-shortcuts-trigger={lizardKey}
            >
                <Component {...props} />
            </span>
        )
    }
}
